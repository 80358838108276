import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Dr. Jessica Krueger" subtitle="Secondary Principal" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "685px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8668cee0255a9152a7e7430da38f8179/0a2b8/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAwT/2gAMAwEAAhADEAAAAZTytRYRpeTPdVjPWkMCf//EAB4QAAICAgIDAAAAAAAAAAAAAAECAxIABBEhIiMx/9oACAEBAAEFAkWzTa4RMiPDP2p+lKhjg1I6yN7Nfynt3//EABgRAAIDAAAAAAAAAAAAAAAAAAECABAR/9oACAEDAQE/AUUFbyf/xAAZEQADAAMAAAAAAAAAAAAAAAAAAQIQESH/2gAIAQIBAT8Bumqw+m2f/8QAHBAAAgICAwAAAAAAAAAAAAAAAAEQEQIhEjFR/9oACAEBAAY/Aki04sS1uF61ZWIuXdbGYx//xAAfEAACAgIBBQAAAAAAAAAAAAAAAREhMVFBEGFxkdH/2gAIAQEAAT8h7ml9710uKpZHoJwxooyIZMqMKaUD+j8J6j5GqTx6KrhWN5Sf/9oADAMBAAIAAwAAABCvyo3/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAwEBPxDoMTiiIP/EABoRAAICAwAAAAAAAAAAAAAAAAAhARARQWH/2gAIAQIBAT8QWNUzMnY//8QAHhABAQEAAgIDAQAAAAAAAAAAAREAITFBUWFxsfD/2gAIAQEAAT8QUBlS4FLSxnFMLnIoHzjVsAvqvzDzEUwDRJsC9H971b+C0tR19mVxkFYPlpSEAEegATEWORI+wxQ8Hiet/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/8668cee0255a9152a7e7430da38f8179/0a2b8/01.jpg",
              "srcSet": ["/static/8668cee0255a9152a7e7430da38f8179/f93b5/01.jpg 300w", "/static/8668cee0255a9152a7e7430da38f8179/b4294/01.jpg 600w", "/static/8668cee0255a9152a7e7430da38f8179/0a2b8/01.jpg 685w"],
              "sizes": "(max-width: 685px) 100vw, 685px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`As always, we have had a school year in which our students have engaged in rich learning experiences and deepened their knowledge and skills.`}</p>
    </blockquote>
    <p>{`The school year was one in which we had to ensure the health and safety of our students and community as a whole, as we navigated the various waves of the COVID-19 pandemic. To ensure we had rich learning experiences while maintaining a safe learning environment, we utilised two different modes of learning. For most of the school year, students were in the Blended Learning Mode. They engaged in both on-campus and asynchronous learning, and were grouped in small cohorts. For MYP students, they were on campus 2.5 days per week and the DP students were on campus 3 days per week. When necessary or mandated by the authorities, students shifted to the Remote Learning Mode and engaged in all of their classes via Zoom or other appropriate apps, such as Gather or Google Meet.`}</p>
    <p>{`While everyone would have preferred full on-campus learning for our students, we found silver linings in our time in both the Blended and Remote Modes. Students gained greater ownership and autonomy over their learning. They learned new skills in communication, self-management and collaboration. Teachers reflected deeply on the scope of their units to prioritise the key skills and concepts, freeing up space in the learning opportunities for students to deepen and expand their understanding in unique ways. In our units, there are more direct connections to personalised learning and student choice.`}</p>
    <p>{`A hallmark of our school year was the introduction of our Advisory Programme in the secondary school. Students engaged in small groups to deepen their social-emotional learning and skills. We utilised a resource called Character Strong to launch our programme. As the year progressed, we shifted to other resources as well. There was an intentional structure built for our programme that ties together our PSE curriculum, ATL Skills, Digital Literacy, and the key elements of the Character strong programme. Using our Advisory Philosophy Statement, we further developed our programme.`}</p>
    <p>{`Our Diploma Programme (DP) students had altered experiences due to the pandemic. For both the grade 12 and grade 11 students, the learning expectations for the students were reduced by the IB, and criteria for the courses and the assessment tasks were lessened. The grade 12 students were unable to sit their exams due to the pandemic, and entered into the non-Exam route that was offered by the IB. While our grade 11 students have lessened expectations, we are planning for and preparing our students to take the IB exams in May, 2022.`}</p>
    <p>{`Finally, as this is my first year at PSI, I wish to thank all members of the community - students, staff and parents - for their ongoing support of our school and for always making me feel welcome into the community.  It is an honour to lead in such a fabulous school.`}</p>
    <p><strong parentName="p">{`Dr. Jessica Krueger`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Secondary Principal`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      